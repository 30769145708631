import { useTranslations } from 'next-intl'

import { Box, ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import { getDefaultStartDateByPriceType } from '@/open-web/services/calculators/utils/startDateUtils'
import { sumPricesByType } from '@/open-web/services/calculators/utils/utils'
import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { Divider } from '@/shared/components/Divider'
import { getCurrentPrice } from '@/shared/components/Price/utils'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'
import { useDeliveryInfo } from '@/shared/utils/gtm/gtmHelper'
import {
  SEASON_ENERGY_PRICE_ELEMENTS,
  TARIFF_ELEMENT_TYPE_PREFIX,
  isEnergyElement,
} from '@/shared/utils/tariffElementUtils'

import {
  calculateFinnishMonthlySavings,
  costToString,
  getEnergyPriceElements,
  getMonthlyFeeElements,
} from '../../utils'
import { MonthlyFeeRows } from '../MonthlyFeeRows'
import { MonthlySavings } from '../MonthlySavings'
import { TotalPriceRow } from '../TotalPriceRow'

export type PriceDetailsProps = {
  contractTemplate: EnrichedContractTemplate
  customerType: CustomerType
  addonsTariffNos: number[]
}

export const FinnishPriceDetails = ({
  contractTemplate,
  customerType,
  addonsTariffNos,
}: PriceDetailsProps) => {
  const { deliveryStartDate, deliveryArea = 'FI' } = useDeliveryInfo()
  const startDate = deliveryStartDate || getDefaultStartDateByPriceType(contractTemplate.priceType)

  const theme = useTheme()
  const t = useTranslations('purchaseFlow')
  const { formatPriceWithUnit } = usePriceToString()

  const isVatIncluded = contractTemplate.customerType === 'PRIVATE'

  const energyElements = getEnergyPriceElements(
    contractTemplate,
    addonsTariffNos,
    startDate,
    deliveryArea,
  )

  const monthlyFeeElements = getMonthlyFeeElements(
    contractTemplate,
    addonsTariffNos,
    startDate,
    deliveryArea,
  )

  const totalMonthlyFees = sumPricesByType(
    contractTemplate,
    contractTemplate.availableAddons.filter((addon) => addonsTariffNos.includes(addon.tariffNo)),
    startDate,
    deliveryArea,
    (type) => type === TARIFF_ELEMENT_TYPE_PREFIX.monthlyFee,
  )

  const totalEnergyPrice = sumPricesByType(
    contractTemplate,
    contractTemplate.availableAddons.filter((addon) => addonsTariffNos.includes(addon.tariffNo)),
    startDate,
    deliveryArea,
    // energy elements also contains elements that we don't want to include in the total price
    // calculation, so we need to filter them out
    (type) => !SEASON_ENERGY_PRICE_ELEMENTS.includes(type) && isEnergyElement(type),
  )

  const { monthlyFeeSavings, energyFeeSavings } = calculateFinnishMonthlySavings(
    addonsTariffNos,
    contractTemplate,
  )

  const energyTariffElement = contractTemplate.tariffElements.find((element) =>
    isEnergyElement(element.type),
  )

  const energyPriceElement = getCurrentPrice(energyTariffElement?.prices)

  const energyPrice = getPriceBaseOnCustomerType(energyPriceElement, customerType)

  const formattedEnergyPrice = costToString(
    {
      value: Number(energyPrice),
      priceUnit: energyTariffElement?.priceUnit ?? undefined,
    },
    isVatIncluded,
    formatPriceWithUnit,
  )

  const hourlyPricePrefix = `${t('hourlyPrice')} + `

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxs}>
      <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap={spacing.xxxxs}>
        <ContentText color={theme.colors.textPrimary} style={{ hyphens: 'auto' }}>
          {t('priceEnergyTitle')}
        </ContentText>
        <ContentText size="s" weight="medium" textAlign="right">
          {contractTemplate.priceType === 'SPOT' && hourlyPricePrefix}
          {formattedEnergyPrice}
        </ContentText>
      </Box>

      <Divider
        color={{ line: theme.colors.borderPrimary }}
        variant="dashed"
        verticalSpacing="xxxxs"
      />

      <MonthlyFeeRows
        monthlyFeeElements={monthlyFeeElements}
        energyElements={energyElements}
        contractTemplate={contractTemplate}
      />

      <Divider
        color={{ line: theme.colors.borderPrimary }}
        variant="solid"
        verticalSpacing="none"
        height={px2rem(2)}
      />

      <TotalPriceRow
        totalMonthlyFees={totalMonthlyFees}
        totalEnergyPrice={totalEnergyPrice}
        monthlyFeeDiscount={monthlyFeeSavings}
        energyFeeDiscount={energyFeeSavings}
        contractTemplate={contractTemplate}
        vatPercentage={energyPriceElement?.vatPercentage}
      />

      {(monthlyFeeSavings.priceExclVat !== 0 || energyFeeSavings.priceExclVat !== 0) && (
        <>
          <Divider
            color={{ line: theme.colors.borderPrimary }}
            variant="dashed"
            verticalSpacing="xxxxs"
          />
          <MonthlySavings
            monthlyFeeSavings={monthlyFeeSavings}
            energyFeeSavings={energyFeeSavings}
            customerType={contractTemplate.customerType}
          />
        </>
      )}
    </Box>
  )
}
