import { useTranslations } from 'next-intl'

import type { ButtonPillProps } from '@fortum/elemental-ui'
import { ButtonPill } from '@fortum/elemental-ui'

import {
  type PurchaseButtonHookProps,
  usePurchaseButtonHandlers,
} from '@/open-web/hooks/usePurchaseButtonHandlers/usePurchaseButtonHandlers'

export type PurchaseButtonProps = ButtonPillProps &
  PurchaseButtonHookProps & {
    invert?: boolean
  }

export const PurchaseButton: React.FC<PurchaseButtonProps> = ({
  contractTemplate,
  invert = false,
  contractProduct,
  campaignConfiguration,
  isSkippingUpgradeFlow,
  entryPointType,
  ...props
}) => {
  const t = useTranslations('purchaseFlow')
  const { handlePurchaseButtonClick } = usePurchaseButtonHandlers({
    contractProduct,
    contractTemplate,
    campaignConfiguration,
    entryPointType,
    isSkippingUpgradeFlow,
  })

  return (
    <ButtonPill
      data-testid="order-contract"
      onClick={handlePurchaseButtonClick}
      status={invert ? 'campaign' : undefined}
      {...props}
    >
      {`${t('order')} ${contractProduct?.cfData.title}`}
    </ButtonPill>
  )
}
