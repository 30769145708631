import { useTranslations } from 'next-intl'

import { Box, ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import { useContractTemplateCostCalculator } from '@/open-web/hooks/useContractTemplateCostCalculator/useContractTemplateCostCalculator'
import { getDefaultStartDateByPriceType } from '@/open-web/services/calculators/utils/startDateUtils'
import { Divider } from '@/shared/components/Divider'
import { discountPercentToString } from '@/shared/components/Price/utils'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'
import { useDeliveryInfo } from '@/shared/utils/gtm/gtmHelper'

import {
  calculateMonthlySavings,
  costToString,
  getEnergyPriceElements,
  getMonthlyFeeElements,
} from '../../utils'
import { ColorfulPriceBar } from '../ColorfulPriceBar'
import { PriceRow } from '../PriceRow'

export type PriceDetailsProps = {
  contractTemplate: EnrichedContractTemplate
  /**
   * Is Colorful price bar hidden.
   */
  isColorfulPriceBarHidden?: boolean
  /**
   * B2B or B2C customer type
   */
  customerType: CustomerType

  /**
   * Selected addons tariff numbers
   */
  addonsTariffNos: number[]
}

/**
 * Present colorful electricity price bar and monthlyFee elements
 */
export const PriceDetails = ({
  contractTemplate,
  isColorfulPriceBarHidden,
  customerType,
  addonsTariffNos,
}: PriceDetailsProps) => {
  const { deliveryStartDate, estimatedConsumption, deliveryArea } = useDeliveryInfo()
  const startDate = deliveryStartDate || getDefaultStartDateByPriceType(contractTemplate.priceType)
  const isVatIncl = contractTemplate.customerType === 'PRIVATE'

  const theme = useTheme()
  const { formatPriceWithUnit, formatPrice } = usePriceToString()
  const t = useTranslations('purchaseFlow')
  const energyElements = getEnergyPriceElements(
    contractTemplate,
    addonsTariffNos,
    startDate,
    deliveryArea,
  )

  const monthlyFeeElements = getMonthlyFeeElements(
    contractTemplate,
    addonsTariffNos,
    startDate,
    deliveryArea,
  )

  const cost = useContractTemplateCostCalculator({
    contractTemplate,
    area: deliveryArea,
    startDate,
    estimatedYearlyConsumption: estimatedConsumption,
    selectedAddonsTariffNos: addonsTariffNos,
  })
  const monthlySavings = calculateMonthlySavings(cost, isVatIncl)
  const shouldHideMonthlyFeeDivider = deliveryArea === 'FI'

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxs}>
      <Box display="flex" justifyContent="space-between" flexWrap="nowrap" gap={spacing.xxxxs}>
        <ContentText color={theme.colors.textPrimary} style={{ hyphens: 'auto' }}>
          {t('priceEnergyTitle')}
        </ContentText>
        <ContentText size="s" weight="medium" textAlign="right">
          {cost
            ? costToString(cost.energyPriceSumWithDiscounts, isVatIncl, formatPriceWithUnit)
            : '--'}
        </ContentText>
      </Box>
      {!isColorfulPriceBarHidden && (
        <ColorfulPriceBar energyElements={energyElements} isVatIncl={isVatIncl} />
      )}
      <Divider
        color={{ line: theme.colors.borderPrimary }}
        variant="dashed"
        verticalSpacing="xxxxs"
      />
      {monthlyFeeElements.map(
        (
          { key, elementName, priceWithDiscount, priceWithoutDiscount, discountDuration },
          index,
        ) => {
          const discountPercent = priceWithDiscount
            ? discountPercentToString(
                priceWithoutDiscount.priceInclVat,
                priceWithDiscount.priceInclVat,
                formatPrice,
              )
            : null

          return (
            <PriceRow
              priceItemFontSize="s"
              key={key}
              label={elementName}
              price={costToString(
                priceWithDiscount ?? priceWithoutDiscount,
                isVatIncl,
                formatPriceWithUnit,
              )}
              priceWithoutDiscounts={
                priceWithDiscount
                  ? costToString(priceWithoutDiscount, isVatIncl, formatPriceWithUnit)
                  : undefined
              }
              description={
                //TODO: Fix discount duration to handle null duration for lifetime discounts https://fortum.atlassian.net/browse/LA009209-1686
                discountDuration && discountPercent
                  ? t('discount', {
                      discountDuration,
                      discountDurationType: 'MONTH',
                      discountType: 'monthlyFee',
                      discountPercent: discountPercent,
                    })
                  : undefined
              }
              isDividerHidden={monthlyFeeElements?.length - 1 === index}
              testId={index}
            />
          )
        },
      )}

      {!shouldHideMonthlyFeeDivider && (
        <Divider
          color={{ line: theme.colors.borderPrimary }}
          variant="solid"
          verticalSpacing="none"
          height={px2rem(2)}
        />
      )}
      {cost?.estimatedMonthlyCostWithDiscounts && (
        <Box
          data-testid="estimated-monthly-cost-with-discounts"
          display="flex"
          flexDirection="column"
          gap={spacing.xxs}
        >
          <PriceRow
            priceItemFontSize="m"
            priceStrikeThroughSize="s"
            label={t('priceBreakdown.priceDetails.monthlyEstimatedCost')}
            price={costToString(
              cost.estimatedMonthlyCostWithDiscounts,
              isVatIncl,
              formatPriceWithUnit,
            )}
            priceWithoutDiscounts={
              cost.estimatedMonthlyCost.priceExclVat !==
              cost.estimatedMonthlyCostWithDiscounts.priceExclVat
                ? costToString(cost.estimatedMonthlyCost, isVatIncl, formatPriceWithUnit)
                : undefined
            }
            isDividerHidden
          />
          <ContentText size="xs" width="100%" textColor={theme.colors.textSecondary}>
            {t(`priceBreakdown.priceDetails.monthlyEstimatedCostVatDisclaimer`, {
              customerType: isVatIncl ? 'private' : 'enterprise',
            })}
          </ContentText>
          <ContentText size="xs" width="100%" textColor={theme.colors.textSecondary}>
            {t('priceBreakdown.priceDetails.monthlyEstimatedCostVatAddition', { customerType })}
          </ContentText>
        </Box>
      )}

      {monthlySavings && (
        <>
          <Divider
            color={{ line: theme.colors.borderPrimary }}
            variant="dashed"
            verticalSpacing="xxxxs"
          />
          <ContentText
            data-testid="monthly-savings-value"
            textAlign="center"
            textColor={theme.colors.textPositive}
          >
            {t('priceBreakdown.priceDetails.monthlySavings', {
              monthlySavings: costToString(
                {
                  value: monthlySavings,
                  priceUnit: cost?.estimatedMonthlyCost.priceUnit,
                },
                false,
                formatPriceWithUnit,
              ),
            })}
          </ContentText>
        </>
      )}
    </Box>
  )
}
