import { useTranslations } from 'next-intl'

import { Box, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { NotificationBanner } from '@/shared/components/NotificationBanner'
import { getFuturePrice } from '@/shared/components/Price/utils'
import type { TariffElement } from '@/shared/graphql/schema/commonBackend/graphql'
import { useCommonDateFormatter } from '@/shared/hooks/useCommonDateFormatter'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { isEnergyElement, isMonthlyFeeElement } from '@/shared/utils/tariffElementUtils'

import { costToString } from '../../utils'

export type FuturePriceNotificationsProps = {
  monthlyTariffElement?: TariffElement
  energyTariffElement?: TariffElement
  contractTemplate: EnrichedContractTemplate
}

export const FuturePriceNotifications = ({ contractTemplate }: FuturePriceNotificationsProps) => {
  const t = useTranslations('purchaseFlow.priceBreakdown')
  const { formatPriceWithUnit } = usePriceToString()
  const { formatDate } = useCommonDateFormatter()

  const energyTariffElement = contractTemplate.tariffElements.find((element) =>
    isEnergyElement(element.type),
  )
  const monthlyTariffElement = contractTemplate.tariffElements.find((element) =>
    isMonthlyFeeElement(element.type),
  )

  const futureEnergyPriceElement = getFuturePrice(energyTariffElement?.prices)
  const futureMonthlyPriceElement = getFuturePrice(monthlyTariffElement?.prices)

  if (!futureEnergyPriceElement && !futureMonthlyPriceElement) {
    return null
  }

  const { customerType } = contractTemplate
  const isVatIncluded = customerType === 'PRIVATE'

  const futureEnergyPrice = futureEnergyPriceElement
    ? getPriceBaseOnCustomerType(futureEnergyPriceElement, customerType)
    : undefined
  const futureMonthlyPrice = futureMonthlyPriceElement
    ? getPriceBaseOnCustomerType(futureMonthlyPriceElement, customerType)
    : undefined

  const formattedFutureEnergyPrice = costToString(
    {
      value: Number(futureEnergyPrice),
      priceUnit: energyTariffElement?.priceUnit ?? undefined,
    },
    isVatIncluded,
    formatPriceWithUnit,
  )

  const formattedFutureMonthlyPrice = costToString(
    {
      value: Number(futureMonthlyPrice),
      priceUnit: monthlyTariffElement?.priceUnit ?? undefined,
    },
    isVatIncluded,
    formatPriceWithUnit,
  )

  return (
    <Box
      display="flex"
      data-testid="future-price-notifications"
      flexDirection="column"
      gap={spacing.s}
    >
      {futureEnergyPriceElement && (
        <NotificationBanner
          data-testid="energy-future-price-notification"
          key="energyFuturePriceNotification"
          type="info"
          message={t('twoTimeMeasurement.energy.contractFuturePrice', {
            type: 'energy',
            date: formatDate(futureEnergyPriceElement?.fromDate),
            price: formattedFutureEnergyPrice,
          })}
          shouldHideCloseButton
        />
      )}
      {futureMonthlyPriceElement && (
        <NotificationBanner
          data-testid="monthly-fee-future-price-notification"
          key="monthlyFeeFuturePriceNotification"
          type="info"
          message={t('twoTimeMeasurement.energy.contractFuturePrice', {
            type: 'monthlyFee',
            date: formatDate(futureMonthlyPriceElement?.fromDate),
            price: formattedFutureMonthlyPrice,
          })}
          shouldHideCloseButton
        />
      )}
    </Box>
  )
}
