import { usePathname, useRouter } from '@/i18n/navigation'
import type { EnterpriseCheckoutRoute, PrivateCheckoutRoute } from '@/shared/routes'
import { routes } from '@/shared/routes'

export const usePurchaseFlowNavigation = () => {
  const router = useRouter()
  const pathname = usePathname()

  const navigateNextPage = (
    route: PrivateCheckoutRoute,
    enterpriseRoute: EnterpriseCheckoutRoute,
  ) => {
    router.push({ pathname: pathname.startsWith(routes.ENTERPRISE_HOME) ? enterpriseRoute : route })
  }

  return navigateNextPage
}
