import { useTranslations } from 'next-intl'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { discountPercentToString } from '@/shared/components/Price/utils'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import type { EnergyPriceElement, MonthlyFeeElement } from '../../types'
import { costToString } from '../../utils'
import { PriceRow } from '../PriceRow'

export type MonthlyFeeRowsProps = {
  monthlyFeeElements: MonthlyFeeElement[]
  energyElements: EnergyPriceElement[]
  contractTemplate: EnrichedContractTemplate
}

export const MonthlyFeeRows = ({
  monthlyFeeElements,
  energyElements,
  contractTemplate,
}: MonthlyFeeRowsProps) => {
  const isVatIncl = contractTemplate.customerType === 'PRIVATE'
  const t = useTranslations('purchaseFlow')
  const { formatPriceWithUnit, formatPrice } = usePriceToString()

  const monthlyFeeRows = monthlyFeeElements.map(
    ({ key, elementName, priceWithDiscount, priceWithoutDiscount, discountDuration }) => {
      const basePrice = costToString(
        priceWithDiscount ?? priceWithoutDiscount,
        isVatIncl,
        formatPriceWithUnit,
      )

      const environmentalEnergySurchargeElement =
        key === 'environmental_monthlyFee'
          ? energyElements.find((element) => element.key === 'environmental_energy')
          : null

      const surcharge = getPriceBaseOnCustomerType(
        environmentalEnergySurchargeElement?.price,
        contractTemplate.customerType,
      )

      const formattedSurcharge =
        surcharge && !Number.isNaN(surcharge)
          ? ` + ${costToString(
              {
                value: Number(surcharge),
                priceUnit: environmentalEnergySurchargeElement?.priceUnit,
              },
              isVatIncl,
              formatPriceWithUnit,
            )}`
          : ''

      const price = `${basePrice}${formattedSurcharge}`
      const discountPercentString = discountPercentToString(
        getPriceBaseOnCustomerType(priceWithoutDiscount, contractTemplate.customerType),
        getPriceBaseOnCustomerType(priceWithDiscount, contractTemplate.customerType),
        formatPrice,
      )

      return {
        key,
        label: elementName,
        price,
        priceWithoutDiscounts: priceWithDiscount
          ? costToString(priceWithoutDiscount, isVatIncl, formatPriceWithUnit)
          : undefined,
        //TODO: Fix discount duration to handle null duration for lifetime discounts https://fortum.atlassian.net/browse/LA009209-1686
        description: discountDuration
          ? t('discount', {
              discountDurationType: 'MONTH',
              discountDuration,
              discountPercent: discountPercentString,
              discountType: 'monthlyFee',
            })
          : undefined,
      }
    },
  )

  return (
    <>
      {monthlyFeeRows.map((row, index) => (
        <PriceRow
          key={row.key}
          priceItemFontSize="s"
          label={row.label}
          price={row.price}
          priceWithoutDiscounts={row.priceWithoutDiscounts}
          description={row.description}
          isDividerHidden={monthlyFeeRows.length - 1 === index}
        />
      ))}
    </>
  )
}
