'use client'

import { useClientSideConfs } from '@/shared/providers/appEnv'

import { SectionError } from './SectionError'
import type { FallbackComponentType } from '../ErrorBoundary/types'

/**
 * A component to show in place of a section if there is an error in the data and the section cannot be rendered.
 */
export const SectionErrorClientComponent: FallbackComponentType = ({ ...props }) => {
  const clientSideConfs = useClientSideConfs()

  return <SectionError {...props} appEnv={clientSideConfs.appEnv} />
}
