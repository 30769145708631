import { useTranslations } from 'next-intl'

import { Box, ContentText, fontWeights, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type {
  PriceDetailsFieldsFragment,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import { useCommonDateFormatter } from '@/shared/hooks/useCommonDateFormatter'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { StyledHeading } from '../../StyledHeading'
import { getActiveOrFutureMonthlyFee, getCurrentPrice, getFuturePrice } from '../utils'

export type TwoTimeMeasurementPricesProps = {
  nightPrices?: TariffElement
  dayPrices?: TariffElement
  winterPrices?: TariffElement
  otherSeasonPrices?: TariffElement
  monthlyFees?: TariffElement
  contractTemplate: EnrichedContractTemplate
}

export const TwoTimeMeasurementPrices = ({
  nightPrices,
  dayPrices,
  winterPrices,
  otherSeasonPrices,
  monthlyFees,
  contractTemplate,
}: TwoTimeMeasurementPricesProps) => {
  const { formatPriceWithUnit } = usePriceToString()
  const t = useTranslations('purchaseFlow.priceBreakdown')
  const { formatDate } = useCommonDateFormatter()
  const applicableMonthlyFee = getActiveOrFutureMonthlyFee(monthlyFees)
  const { customerType } = contractTemplate
  const getFuturePriceLabel = (price: PriceDetailsFieldsFragment, element?: TariffElement) =>
    t('twoTimeMeasurement.energy.futurePrice', {
      date: formatDate(price.fromDate),
      monthlyFee: formatPriceWithUnit(
        getPriceBaseOnCustomerType(applicableMonthlyFee ?? undefined, customerType),
        monthlyFees?.priceUnit,
      ),
      energyPrice: formatPriceWithUnit(
        getPriceBaseOnCustomerType(price, customerType),
        element?.priceUnit,
      ),
    })

  const futureNightPrice = getFuturePrice(nightPrices?.prices)
  const futureDayPrice = getFuturePrice(dayPrices?.prices)
  const futureWinterPrice = getFuturePrice(winterPrices?.prices)
  const futureOtherSeasonPrice = getFuturePrice(otherSeasonPrices?.prices)

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <StyledHeading level={4} textAlign="center">
          {t('twoTimeMeasurement.nightPrice')}
        </StyledHeading>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatPriceWithUnit(
              getPriceBaseOnCustomerType(getCurrentPrice(nightPrices?.prices), customerType),
              nightPrices?.priceUnit,
            )}
            &nbsp;
          </ContentText>
          <ContentText display="inline">{t('twoTimeMeasurement.energy.night')}</ContentText>
        </Box>
        {futureNightPrice && (
          <ContentText>{getFuturePriceLabel(futureNightPrice, nightPrices)}</ContentText>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={spacing.xxs}>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatPriceWithUnit(
              getPriceBaseOnCustomerType(getCurrentPrice(dayPrices?.prices), customerType),
              dayPrices?.priceUnit,
            )}
            &nbsp;
          </ContentText>
          <ContentText display="inline">{t('twoTimeMeasurement.energy.day')}</ContentText>
        </Box>
        {futureDayPrice && (
          <ContentText>{getFuturePriceLabel(futureDayPrice, dayPrices)}</ContentText>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={spacing.xxs}>
        <StyledHeading level={4} textAlign="center">
          {t('twoTimeMeasurement.seasonalPrice')}
        </StyledHeading>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatPriceWithUnit(
              getPriceBaseOnCustomerType(getCurrentPrice(winterPrices?.prices), customerType),
              winterPrices?.priceUnit,
            )}
            &nbsp;
          </ContentText>
          <ContentText>{t('twoTimeMeasurement.energy.winterDay')}</ContentText>
        </Box>
        {futureWinterPrice && (
          <ContentText>{getFuturePriceLabel(futureWinterPrice, winterPrices)}</ContentText>
        )}
      </Box>

      <Box display="flex" flexDirection="column" mt={spacing.xxs}>
        <Box display="flex">
          <ContentText fontWeight={fontWeights.bold}>
            {formatPriceWithUnit(
              getPriceBaseOnCustomerType(getCurrentPrice(otherSeasonPrices?.prices), customerType),
              otherSeasonPrices?.priceUnit,
            )}
            &nbsp;
          </ContentText>
          <ContentText display="inline">{t('twoTimeMeasurement.energy.otherSeason')}</ContentText>
        </Box>
        {futureOtherSeasonPrice && (
          <ContentText>
            {getFuturePriceLabel(futureOtherSeasonPrice, otherSeasonPrices)}
          </ContentText>
        )}
      </Box>
    </Box>
  )
}
