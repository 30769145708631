import { useTranslations } from 'next-intl'

import { ContentText } from '@fortum/elemental-ui'

import type { CostDetails } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { costToString } from '../../utils'

export type MonthlySavingsProps = {
  monthlyFeeSavings: CostDetails
  energyFeeSavings: CostDetails
  customerType: EnrichedContractTemplate['customerType']
}

export const MonthlySavings = ({
  monthlyFeeSavings,
  energyFeeSavings,
  customerType,
}: MonthlySavingsProps) => {
  const t = useTranslations('purchaseFlow')
  const theme = useTheme()
  const { formatPriceWithUnit } = usePriceToString()
  const isVatIncl = customerType === 'PRIVATE'

  const savingsParts: string[] = []

  if (monthlyFeeSavings.priceExclVat !== 0) {
    savingsParts.push(costToString(monthlyFeeSavings, isVatIncl, formatPriceWithUnit))
  }

  if (energyFeeSavings.priceExclVat !== 0) {
    savingsParts.push(costToString(energyFeeSavings, isVatIncl, formatPriceWithUnit))
  }

  const savingsString = savingsParts.join(' + ')

  if (!savingsString) {
    return null
  }

  return (
    <ContentText
      data-testid="monthly-savings-value"
      textAlign="center"
      textColor={theme.colors.textPositive}
    >
      {t('priceBreakdown.priceDetails.monthlySavings', {
        monthlySavings: savingsString,
      })}
    </ContentText>
  )
}
