import { useTranslations } from 'next-intl'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import type { CostDetails } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { calculateFinnishContractPrice } from '@/open-web/services/calculators/calculateFinnishContractPrice'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFullLocale } from '@/shared/locale'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { PriceRow } from '../PriceRow'

export type TotalPriceRowProps = {
  totalMonthlyFees: CostDetails
  totalEnergyPrice: CostDetails
  monthlyFeeDiscount: CostDetails
  energyFeeDiscount: CostDetails
  contractTemplate: EnrichedContractTemplate
  vatPercentage?: number
}

export const TotalPriceRow = ({
  totalMonthlyFees,
  totalEnergyPrice,
  contractTemplate,
  monthlyFeeDiscount,
  energyFeeDiscount,
  vatPercentage,
}: TotalPriceRowProps) => {
  const t = useTranslations('purchaseFlow')
  const locale = useFullLocale()
  const theme = useTheme()
  const { buildFormatter, formatPrice } = usePriceToString()
  const isVatIncl = contractTemplate.customerType === 'PRIVATE'

  if (totalMonthlyFees === undefined || totalEnergyPrice === undefined) {
    return null
  }

  const {
    energyPrice,
    monthlyFeePrice,
    energyPriceWithoutDiscounts,
    monthlyFeeWithoutDiscounts,
    isDiscounted,
  } = calculateFinnishContractPrice({
    contractTemplate,
    totalMonthlyFees,
    totalEnergyPrice,
    monthlyFeeDiscount,
    energyFeeDiscount,
    locale,
  })

  const formatEnergy = buildFormatter(totalEnergyPrice.priceUnit)
  const formatMonthlyFee = buildFormatter(totalMonthlyFees.priceUnit)

  return (
    <Box data-testid="total-price" display="flex" flexDirection="column" whiteSpace="nowrap">
      <PriceRow
        priceItemFontSize="m"
        priceStrikeThroughSize="s"
        label={`${t('priceBreakdown.priceDetails.total')}`}
        price={`${formatMonthlyFee(monthlyFeePrice)} + ${formatEnergy(energyPrice)}`}
        priceWithoutDiscounts={
          isDiscounted
            ? `${formatMonthlyFee(monthlyFeeWithoutDiscounts)} + ${formatEnergy(energyPriceWithoutDiscounts)}`
            : ''
        }
        isDividerHidden
      />
      {contractTemplate.priceType === 'SPOT' && (
        <PriceRow
          priceItemFontSize="xs"
          priceStrikeThroughSize="s"
          label=""
          price={`+ ${t('hourlyPrice')} `}
          isDividerHidden
        />
      )}
      {contractTemplate.priceType === 'HYBRID' && (
        <PriceRow
          priceItemFontSize="xs"
          priceStrikeThroughSize="s"
          label=""
          price={`+/- ${t('consumptionImpact')}`}
          isDividerHidden
        />
      )}
      {isVatIncl && vatPercentage && (
        <ContentText size="xs" width="100%" mt={spacing.xxs} textColor={theme.colors.textSecondary}>
          {t(`priceBreakdown.priceDetails.totalPriceIncludesVat`, {
            vatPercentage: formatPrice(vatPercentage, { minimumFractionDigits: 0 }),
          })}
        </ContentText>
      )}
    </Box>
  )
}
