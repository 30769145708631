// eslint-disable-next-line no-restricted-imports
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { validateCustomerType } from '@/shared/components/Price/utils'
import { useSelectedAddonsTariffNos } from '@/shared/components/PriceBreakdown/hooks/useSelectedAddonsTariffNos'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { routes } from '@/shared/routes'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type {
  CustomerType,
  EntryPointType,
  SelectedAddons,
} from '@/shared/store/slices/selectedContractSlice'
import { filterOnlySpecialAddons, filterOutSpecialAddons } from '@/shared/utils/addonsUtils'
import { logError } from '@/shared/utils/error'
import {
  getPriceElements,
  mapContractAndAddons,
  useDeliveryInfo,
} from '@/shared/utils/gtm/gtmHelper'
import { contractItemsEvent } from '@/shared/utils/sendGTMEvent'

import { usePurchaseFlowNavigation } from '../usePurchaseFlowNavigation'

export type PurchaseButtonHookProps = {
  contractTemplate: EnrichedContractTemplate
  contractProduct: EnrichedContractProduct
  campaignConfiguration: CampaignConfigurationEntry

  isSkippingUpgradeFlow?: boolean
  /**
   * Optional information about purchase entry point (source page). Used for navigation logic.
   */
  entryPointType?: EntryPointType

  onPurchaseButtonCallback?: VoidFunction
}

export const usePurchaseButtonHandlers = ({
  contractProduct,
  contractTemplate,
  campaignConfiguration,
  entryPointType = 'default',
  isSkippingUpgradeFlow,
  onPurchaseButtonCallback,
}: PurchaseButtonHookProps) => {
  const isSweden = process.env.NEXT_PUBLIC_COUNTRY === 'SE'
  const isFinland = process.env.NEXT_PUBLIC_COUNTRY === 'FI'

  // That is correct that `next` usePathname is used, cuz we need current pathname not the route
  const pathname = usePathname()
  const {
    selectContract,
    setSelectedAddons,
    setContractProduct: setSelectedContractProduct,
    setCampaignConfiguration,
    enterprise: enterpriseSelectedContract,
    private: privateSelectedContract,
    setEntryPointDetails,
  } = useGlobalStore((store) => store.selectedContract)
  const {
    delivery: { deliveryAddressType },
  } = useGlobalStore((store) => store.checkoutFlow)
  const navigateNextPage = usePurchaseFlowNavigation()
  const { selectedAddons, contractTemplate: selectedContractTemplate } =
    contractTemplate.customerType === 'PRIVATE'
      ? privateSelectedContract
      : enterpriseSelectedContract

  const { itemListName, listId } = useGlobalStore((store) => store.gtm)

  const { deliveryStartDate, estimatedConsumption, deliveryArea } = useDeliveryInfo()

  const { selectedAddonsTariffNos } = useSelectedAddonsTariffNos({ contractTemplate })

  const hasAvailableAddons = useMemo(() => {
    return filterOutSpecialAddons(contractTemplate.availableAddons)?.length > 0
  }, [contractTemplate.availableAddons])

  const getPreselectedAddons = () => {
    //Only addons that exist on selected contract and might be preselected
    const preselectedAddons = filterOnlySpecialAddons(contractTemplate.availableAddons)

    return Object.entries(selectedAddons).reduce<SelectedAddons>((acc, curr) => {
      if (preselectedAddons.some((addon) => addon.tariffNo === curr[1].value)) {
        return { ...acc, [curr[0]]: curr[1] }
      }
      return acc
    }, {})
  }

  const handlePurchaseButtonClick = () => {
    const { error: contractError } = validateCustomerType(contractTemplate)

    if (contractError) {
      throw logError(contractError)
    }

    const contractCustomerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType

    selectContract(contractTemplate, contractCustomerType)

    setCampaignConfiguration(campaignConfiguration, contractCustomerType)

    //Filter contractTemplates to only those from same campaign
    setSelectedContractProduct(
      {
        ...contractProduct,
        contractTemplates: contractProduct.contractTemplates.filter(
          (ct) => ct.campaignId === campaignConfiguration.campaignId,
        ),
      },
      contractCustomerType,
    )

    //Reset selected addons when contract is changed
    if (
      contractTemplate.templateNo !== selectedContractTemplate?.templateNo ||
      contractTemplate.campaignId !== selectedContractTemplate?.campaignId
    ) {
      setSelectedAddons(getPreselectedAddons(), contractCustomerType)
    }

    if (onPurchaseButtonCallback) {
      onPurchaseButtonCallback()
    }

    // Set additional information about the entry point for the purchase flow
    setEntryPointDetails(
      entryPointType,
      pathname,
      contractTemplate.customerType.toLocaleLowerCase() as CustomerType,
    )

    const hasValidDeliveryAreaAndEstimatedConsumption = estimatedConsumption && deliveryArea
    const hasNoAddonsAndValidDeliveryAreaAndEstimatedConsumption =
      !hasAvailableAddons && hasValidDeliveryAreaAndEstimatedConsumption

    // Skip upgrade flow in Sweden if there are no available addons and the delivery area and estimated consumption are valid
    // or if the user is skipping the upgrade flow (e.g. from the quiz) but has a valid delivery area and estimated consumption
    const isAbleToSkipUpgradeFlowInSweden =
      isSweden &&
      (hasNoAddonsAndValidDeliveryAreaAndEstimatedConsumption ||
        (isSkippingUpgradeFlow && hasValidDeliveryAreaAndEstimatedConsumption))

    // Skip upgrade flow in Finland if there are no available addons or if the user is skipping the upgrade flow (e.g. from the quiz)
    const isAbleToSkipUpgradeFlowInFinland =
      isFinland && (!hasAvailableAddons || isSkippingUpgradeFlow)

    if (isAbleToSkipUpgradeFlowInSweden || isAbleToSkipUpgradeFlowInFinland) {
      navigateNextPage(routes.CHECKOUT, routes.ENTERPRISE_CHECKOUT)
    } else {
      navigateNextPage(routes.UPGRADE_FLOW, routes.ENTERPRISE_UPGRADE_FLOW)
    }

    if (contractTemplate) {
      const priceElements = getPriceElements(
        contractTemplate,
        deliveryArea,
        estimatedConsumption,
        selectedAddonsTariffNos,
        deliveryStartDate,
      )
      const items = mapContractAndAddons(contractTemplate, [], priceElements, deliveryAddressType)

      contractItemsEvent(
        'select_item',
        {
          item_list_id: listId,
          item_list_name: itemListName,
        },
        items,
      )
    }
  }

  return { handlePurchaseButtonClick }
}
