import { useTranslations } from 'next-intl'
import { useMemo } from 'react'

import { Box, ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import { browserEnvs } from '@/shared/envs'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import { sumPrices } from '@/shared/utils/contractTemplateUtils'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'
import { isEnergyDiscountElement } from '@/shared/utils/tariffElementUtils'
import type { Theme } from '@/style/theme'

import { ColorfulPriceBlock } from './ColorfulPriceBlock'
import type { EnergyPriceElement } from '../../types'

type ColorfulPriceBarProps = {
  /**
   * Energy elements presented on the bar
   */
  energyElements: EnergyPriceElement[]
  /**
   * If true VAT will be included in the prices
   */
  isVatIncl?: boolean
}

type EnergyElement =
  | EnergyPriceElement
  | {
      price: number
      priceUnit: string
      elementName: string
      key: string
    }

export const ColorfulPriceBar = ({ energyElements, isVatIncl }: ColorfulPriceBarProps) => {
  const theme = useTheme()
  const config = getConfig(theme)
  const countryCode = browserEnvs.NEXT_PUBLIC_COUNTRY
  const tPriceDetails = useTranslations('purchaseFlow.priceBreakdown.priceDetails')
  const tPriceBreakdown = useTranslations(
    `purchaseFlow.priceBreakdown.elementLabels.${countryCode}`,
  )
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const { formatPriceWithUnit } = usePriceToString()

  const pricesSum = useMemo(
    () =>
      sumPrices(
        energyElements.map((el) => el.price),
        isVatIncl ? 'priceInclVat' : 'priceExclVat',
      ),
    [energyElements, isVatIncl],
  )
  const blocks = useMemo(
    () => {
      if (isVatIncl && energyElements?.length) {
        return [
          ...energyElements,
          {
            price: sumPrices(
              energyElements.map((el) => el.price),
              'vatAmount',
            ),
            priceUnit: energyElements[0]?.priceUnit,
            elementName: tPriceDetails('vatEnergyElementLabel', {
              vatPercentage: energyElements[0]?.price.vatPercentage,
            }),
            key: 'vat',
          },
        ]
      }
      return energyElements
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [energyElements, isVatIncl],
  )

  const getColor = (key: string) => {
    const color = config[key]

    if (!color) {
      logError(new UiError(`Price breakdown chart color is not defined for key: ${key}`, key))
    }

    return color || theme.colors.chart9
  }

  const getBorderRadius = (index: number) =>
    index === 0
      ? `${px2rem(4)} 0 0 ${px2rem(4)}`
      : index === blocks.length - 1
        ? `0 ${px2rem(4)} ${px2rem(4)} 0`
        : 'none'

  const getLabelWithFallback = (element: EnergyElement) => {
    const parsedKey = element.key.replaceAll('.', '_')

    return tPriceBreakdown.has(parsedKey) ? tPriceBreakdown(parsedKey) : element.elementName
  }

  const getEnergyElementLabel = (energyEl: EnergyElement) =>
    isEnergyDiscountElement(energyEl.key) && 'duration' in energyEl && energyEl.duration?.amount
      ? tPurchaseFlow('discountEnergyElement', {
          elementName: getLabelWithFallback(energyEl),
          duration: energyEl.duration?.amount,
        })
      : getLabelWithFallback(energyEl)

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxs}>
      <Box display="flex" flexWrap="nowrap" gap={spacing.xxxxs}>
        {blocks.map((energyEl, index) => (
          <ColorfulPriceBlock
            key={energyEl.key}
            price={
              typeof energyEl.price === 'number' ? energyEl.price : energyEl.price.priceExclVat
            }
            priceUnit={energyEl.priceUnit}
            pricesSum={pricesSum}
            label={getEnergyElementLabel(energyEl)}
            colors={{ background: getColor(energyEl.key) }}
            boxProps={{
              borderRadius: getBorderRadius(index),
            }}
          />
        ))}
      </Box>
      {blocks.map((energyEl) => {
        const price =
          typeof energyEl.price === 'number' ? energyEl.price : energyEl.price.priceExclVat

        return (
          <Box key={energyEl.key} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={spacing.xxxs}>
              <Box
                backgroundColor={getColor(energyEl.key)}
                height={px2rem(16)}
                width={px2rem(8)}
                borderRadius={px2rem(4)}
              />
              <ContentText size="s" color={theme.colors.textSecondary}>
                {getEnergyElementLabel(energyEl)}
              </ContentText>
            </Box>

            <ContentText size="s" color={theme.colors.textSecondary}>
              {formatPriceWithUnit(price, energyEl.priceUnit)}
            </ContentText>
          </Box>
        )
      })}
    </Box>
  )
}

const getConfig = ({ colors }: Theme): Record<string, string> => ({
  energy: colors.chart6,
  'energy.margin': colors.chart1,
  'discount.energy.margin': colors.chart7,
  'energy.certificate': colors.chart4,
  'energy.consumptionImpact': colors.chart5,
  'environmental_energy.margin': colors.chart3,
  'environmental_discount.energy.margin': colors.chart3,
  vat: colors.chart2,
})
