import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { costToNumber } from '@/shared/components/PriceBreakdown/utils'
import type { FullLocale } from '@/shared/locale'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import type { CostDetails } from './calculateContractTemplateCost'

export type CalculateFinnishContractPriceParams = {
  contractTemplate: EnrichedContractTemplate
  totalMonthlyFees: CostDetails
  totalEnergyPrice: CostDetails
  monthlyFeeDiscount: CostDetails
  energyFeeDiscount: CostDetails
  locale: FullLocale
}

export const calculateFinnishContractPrice = ({
  contractTemplate,
  totalMonthlyFees,
  totalEnergyPrice,
  monthlyFeeDiscount,
  energyFeeDiscount,
}: CalculateFinnishContractPriceParams) => {
  const { customerType } = contractTemplate
  const isVatIncl = customerType === 'PRIVATE'

  const calculateDiscountedPrice = (base: CostDetails, discount: CostDetails) =>
    Number(getPriceBaseOnCustomerType(base, customerType)) -
    Number(getPriceBaseOnCustomerType(discount, customerType))

  const energyPrice = calculateDiscountedPrice(totalEnergyPrice, energyFeeDiscount)
  const monthlyFeePrice = calculateDiscountedPrice(totalMonthlyFees, monthlyFeeDiscount)

  const energyPriceWithoutDiscounts = costToNumber(totalEnergyPrice, isVatIncl)

  const monthlyFeeWithoutDiscounts = costToNumber(totalMonthlyFees, isVatIncl)

  const isDiscounted =
    energyPrice !== energyPriceWithoutDiscounts || monthlyFeePrice !== monthlyFeeWithoutDiscounts

  return {
    energyPrice,
    monthlyFeePrice,
    energyPriceWithoutDiscounts,
    monthlyFeeWithoutDiscounts,
    isDiscounted,
  }
}
